<template>
  <div class="staff-list">
    <h3 class="model-title">企业疫苗接种情况</h3>

    <div class="des">共{{total}}家企业上报</div>
    <div style="padding-bottom: 50px;">
      <van-list
          :immediate-check="false"
          v-model="loading"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          :finished="finished"
          finished-text="没有更多了"
      >
        <div class="list-title">
          <div class="item-choose">序号</div>
          <div class="item-info">企业名称</div>
          <div class="item-sex">企业总人数</div>
          <div class="item-percent">已接种加强针人数</div>
          <div class="item-percent">加强针接种比例</div>
          <div class="item-tip">可接种加强针人数</div>
        </div>
        <div class="check-list" v-for="(item,index) in list" :key="index" >
          <div class="item-choose">
            <span >
              {{index+1}}
            </span>
          </div>

          <div class="item-info" @click="viewDetail(item)">
            {{item.name?item.name:item.infoName}}
          </div>
          <div class="item-sex">
            {{item.count?item.count:"未知"}}
          </div>
          <div class="item-check">
<!--            <span v-if="item.needStrengthen>0" style="color: red;">{{ item.needStrengthenP }}</span>-->
            <span>{{ item.vaccine3 }}</span>
          </div>
          <div class="item-check">
            <span>{{ item.vaccine3P }}</span>
          </div>
          <div class="item-tip" @click="viewDetail(item)">
            <span v-if="item.needStrengthen>0" style="color: red;">{{ item.needStrengthen }}</span>
            <span v-else>0</span>
          </div>


        </div>

      </van-list>
    </div>
    <div class="list-btn">
      <van-row v-if="vacciManage">
            <van-col span="12">
              <van-button size="large" type="info" @click="downLoadType">按企业类型下载</van-button>
            </van-col>
        <van-col span="12">
          <van-button  size="large" type="primary" @click="downLoad">按企业下载</van-button>
        </van-col>

      </van-row>
      <van-row v-else>
        <van-col span="24">
          <van-button  size="large" type="primary" @click="downLoad">按企业下载</van-button>
        </van-col>

      </van-row>
    </div>
    <!--    检查项弹窗-->
    <van-popup v-model="reportPopup" position="right" :style="{ height: '100%',width:'100%' }"  get-container="#app" >
      <div class="model-title" style="line-height: 40px;font-size: 17px;padding-top: 15px;background: #f7f8fa;"><van-icon name="arrow-left" size="28" @click="reportPopup=false" />&nbsp;&nbsp;
        <span style="position:relative;top: -5px;">新冠疫苗接种情况</span>
      </div>
      <div>
        <van-form  :label-width="labelWidth" label-align="right" ref="checkForm">
          <div class="form-item">
            <van-field
                readonly
                clickable
                name="picker"
                :value="recordData.infoName"
                label="企业名称 "
            />
          </div>
          <div class="form-item">
            <van-field
                readonly
                clickable
                name="picker"
                :value="recordData.count"
                label="企业总人数 "
            />
          </div>
          <div class="form-item">
            <van-field
                readonly
                clickable
                name="picker"
                :value="recordData.streetName"
                label="所属街道 "
            />
          </div>
          <div class="form-item">
            <van-field
                readonly
                clickable
                name="picker"
                :value="recordData.type"
                label="企业类型 "
            />
          </div>
          <div class="form-item">
            <van-field
                readonly
                clickable
                name="picker"
                :value="recordData.vaccine1"
                label="仅接种一针人数"
            />
          </div>
          <div class="form-item">
            <van-field
                readonly
                clickable
                name="picker"
                :value="recordData.vaccine1P"
                label="仅一针接种率"
            />
          </div>
          <div class="form-item">
            <van-field
                readonly
                clickable
                name="picker"
                :value="recordData.vaccine2"
                label="仅接种二针人数"
            />
          </div>
          <div class="form-item">
            <van-field
                readonly
                clickable
                name="picker"
                :value="recordData.vaccine2P"
                label="二针接种率"
            />
          </div>
          <div class="form-item">
            <van-field
                readonly
                clickable
                name="picker"
                :value="recordData.vaccine3"
                label="接种加强针人数"
            />
          </div>
          <div class="form-item">
            <van-field
                readonly
                clickable
                name="picker"
                :value="recordData.vaccine3P"
                label="加强针接种率"
            />
          </div>
          <div class="form-item">
            <van-field
                readonly
                clickable
                name="picker"
                :value="recordData.needStrengthen"
                label="可接种加强针人数"
            />
          </div>
          <div class="form-item">
            <van-field
                readonly
                clickable
                name="picker"
                :value="recordData.noVaccine"
                label="未接种人数"
            />
          </div>
        </van-form>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  List,Cell,Col, Row,Icon,Popup,Checkbox,Button,Notify,Tab, Tabs,Calendar,Uploader,Form,Field
} from 'vant';
export default {
  components:{
    [Calendar.name]: Calendar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Button.name]: Button,
    [List.name]: List,
    [Row.name]: Row,
    [Col.name]: Col,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [Checkbox.name]: Checkbox,
    [Notify.name]: Notify,
    [Uploader.name]: Uploader,
    [Form.name]: Form,
    [Field.name]: Field,
    [Icon .name]: Icon
  },
  props:{

  },
  watch:{

  },
  data(){
    return {
      checked:{
      },
      loading:false,
      list:[],
      total:0,
      finished:false,
      error:false,
      refreshing:false,
      dict:{},
      recordPopup:false,
      recordData:{},
      defaultGroup:GlobalConfig.infoGroup,
      params:{
        startTime:"",
        endTime:"",
        size: 8,
        page: 0,
        createBy:"",
        // infoId:localStorage.getItem("wljpttagIds")==GlobalConfig.infoGroup?localStorage.getItem("wljptinfoId"):"",
      },
      minDate:new Date(2021,9,1),
      // 整改窗
      reportPopup:false,
      makerDetail:{
        checkType:'1',
        id:localStorage.getItem("wljptinfoId"),
        name:localStorage.getItem("wljptinfoName"),
        // id:GlobalConfig.dev?GlobalConfig.infoId:"",
      },
      calendarShow:false,
      uploadloading:false,
      vacciManage:false,
      labelWidth:100,
      // urlPrefix:"http://localhost:8362",
      urlPrefix:"/zuul/cultural"
    }
  },
  methods: {
    // 获取
    getList() {
      this.finished = false;
      this.loading = true;
      let _this = this;
      this.list = [];
      this.$http({
        url: this.urlPrefix + "/staffVacci/static/info",
        method: "get",
        params: this.params
      }).then(res => {
        // console.log(res)
        if (res.data.success && res.data.data) {
          _this.list = res.data.data;
          _this.total = res.data.data.length;
          _this.list.map(item=>{
            _this.$set(this.checked,item.id,false);
          })
        }
        if(_this.list.length<this.total){
          this.params.page++;
        }
        this.isFinished();
      }).finally(response => {
        this.loading = false;
      })
    },
    viewDetail(item){
      this.reportPopup = true;
      this.recordData = item;
    },
    isFinished(){
      if(this.list.length>=this.total){
        this.finished = true;
      }
    },
    onLoad1(){
      let _this = this;
      Promise.all([this.getOnLoadList()]).then(res=>{

        _this.finished = true;
        if(res[0].data.success && res[0].data.data){
          _this.list = res[0].data.data;
        }
        /*if(res[0].data.success && res[0].data.data.results){
          if(this.params.page>0){
            _this.list = _this.list.concat(res[0].data.data.results)
          }else{
            _this.list = res[0].data.data.results;
          }
          if(_this.list.length>= _this.total){
            _this.finished = true;
          }else{
            _this.params.page++;
          }
        }*/
      })
    },
    onRefresh(){
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad1();
      /*if(this.list.length<this.total){
        this.loading = true;
        this.params.page++;
        this.onLoad1();
      }else{
        this.loading = false;
        this.refreshing = false;
        this.finished = true;
      }*/

    },
    getOnLoadList(){
      this.loading = true;
      return new Promise(resolve => {
        this.$http({
          url: this.urlPrefix + "/staffVacci/static/info",
          method: "get",
          params: this.params
        }).then(res=>{
          resolve(res);

        }).catch(e=>{
          this.error = true;
        }).finally(e=>{
          this.loading = false;
        })
      })
    },
    downLoad(){
      let url = this.urlPrefix + '/staffVacci/static/info/export?openid=' + localStorage.getItem("wljptuser");
      // let url = 'http://localhost:8362/staffVacci/export?createBy=' + localStorage.getItem("wljptuser");
      window.open(url,"blank")
    },
    downLoadType(){
      let url = this.urlPrefix + '/staffVacci/static/type/export' ;
      // let url = 'http://localhost:8362/staffVacci/export?createBy=' + localStorage.getItem("wljptuser");
      window.open(url,"blank")
    },
    report(){
      this.reportPopup = true;
      this.recordData = {};
    },
    closeReport(){
      this.params.page = 0;
      this.getList();
      this.reportPopup = false;
    }
  },
  mounted(){
    console.log("tagIds=" + localStorage.getItem("wljpttagIds"))
    this.params = {
      size: this.params.size,
      page: 0,
      openid: localStorage.getItem("wljptuser")
    }
    if(localStorage.getItem("wljptuser") && GlobalConfig.vacciOpenIds.includes(localStorage.getItem("wljptuser"))){
      this.vacciManage = true;
    }
    this.getList();
  }
}
</script>

<style scoped lang="less">
.staff-list /deep/.van-tabs__nav--card .van-tab{
  color: #349DFF;
  border-right: 1px solid #349DFF;
}
.staff-list /deep/.van-tabs__nav--card{
  border: 1px solid #349DFF;
  border-right: none;
}
.staff-list /deep/.van-tabs__nav--card .van-tab.van-tab--active{
  color:white;
  background: #349DFF;
}
.staff-list /deep/.van-tabs__nav--card{
  margin: 0px;
}
/deep/ .van-field__control{
  border-bottom: 1px solid #ebedf0;
  padding-left: 16px;
}
.form-item{
  padding: 0px;
  height: 36px;
}
.list-title{
  font-size: 11px;
  text-align: center;
  vertical-align: middle;

  >div{
    display: inline-block;
    width: 50px;
    padding: 0px 2px;
    text-align: center;
  }
}
.staff-list{
  color: #515154;
  .model-title{
    letter-spacing: 2px;
    font-weight: 800;
    text-align: left;
    line-height: 45px;
    position: relative;
    padding: 0px 0px 0px 25px;
    margin: 0px;
    background: #f7f8fa;
    //background: #DFE0DF;
    .van-icon{
      position: absolute;
      left: 0px;
      top: 8px;
      font-weight: normal;
      color: #969799;
    }
  }
  .align-center{
    text-align: center;
  }
  .list-btn{
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: calc(100vw);
  }
}
.des{
  padding: 10px 20px;
  color: rgba(69, 90, 100, 0.6);
  background: #f7f8fa;
}
.check-list{
  padding: 10px 20px 2px;
  font-size: 0.8rem;
  border-bottom: 1px solid #ebedf0;;
  >div{
    display: inline-block;
    width: 50px;
    vertical-align: middle;
    padding: 0px 2px;
    text-align: center;
  }

}
.item-choose{
  width: 30px !important;
  text-align: left !important;
}
.item-info{
  text-align: left !important;
  width: calc(100vw - 295px) !important;
}

</style>
